import React from 'react'
import {Button} from "react-bootstrap";
import {DialogTitle, DialogContent, Dialog, DialogActions, useTheme, useMediaQuery} from '@mui/material';
import '../App.css';

export const ModalDelete = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return(
        <Dialog
            disableBackdropClick
            open={props.show}
            fullScreen={fullScreen}
            onClose={props.onHide}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="backgroundDialog"
        >
            <DialogTitle className="dialogTitle">
                <h4>Delete</h4>
            </DialogTitle>
            <DialogContent style={{display:"grid",textAlign:"center",marginTop:"20px"}}>
                <p>Are you sure you want to delete this record?</p>
            </DialogContent>
            <DialogActions>
                <Button className="btn btn-secondary mr-auto" onClick={props.onHide}>Cancel</Button>
                <Button className="btn btn-danger" onClick={props.handleDelete}>Delete</Button>
            </DialogActions>
        </Dialog>
    );

}