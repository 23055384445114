import React from 'react'
import {Form} from "react-bootstrap";
import Button from "@material-ui/core/Button";
import {Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme} from "@mui/material";

export const EditMultipleContents = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return(
        <Dialog
            disableBackdropClick
            open={props.show}
            fullScreen={fullScreen}
            onClose={props.onHide}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="backgroundDialog"
        >
            <DialogTitle className="dialogTitle">
                <h4>Edit Contents</h4>
            </DialogTitle>
            <DialogContent style={{display:"grid",textAlign:"center"}}>
                <Form>
                    <Form.Group >
                        <p style={{marginTop:"10px"}}>Content</p>
                        <Form.Control as="select" custom onChange={props.handleChange} name="content" >
                            <option value={""} name="content">No Content ID</option>
                            {
                                props.selectContents.map(content => (
                                    <option key={content.id} value={content.id}>{content.name}</option>
                                ))
                            }
                        </Form.Control>
                    </Form.Group>
                </Form>
            </DialogContent>
            <DialogActions>
                {
                    <Button className="buttonColor" onClick={props.commit}>Set</Button>
                }
            </DialogActions>
        </Dialog>
    )

}