import React from 'react';
export const TopBar = (props) => {

    return (
        <>
            <div className="topBar">
                    <img
                        className="logo"
                        src={'/apple-touch-icon.png'}
                        alt={'logo'}
                    />
                    {
                        props.view === 1 ?
                            <div className="headerText">Users Management</div>
                        :
                            ""
                    }
                    {
                        props.view === 2 ?
                            <div className="headerText">Device Management</div>
                        :
                            ""
                    }
                    {
                        props.view === 3 ?
                            <div className="headerText">Content Management</div>
                        :
                            ""
                    }
                    {
                        props.view === 4 ?
                            <div className="headerText">Multimedia Assets</div>
                        :
                            ""
                    }
                    <div className="headerText2" style={{marginRight:"10px",marginTop:"10px"}}>{props.authInfo.username}</div>
            </div>
        </>
    );
}
