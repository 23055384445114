import React, {useState} from 'react'
import {Modal, Button, Form} from "react-bootstrap";
import {MdArrowBack} from "react-icons/md/index";
import {ModalDelete} from "./modalDelete";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import {createFilterOptions} from "@mui/core";
import {DialogTitle, DialogContent, Dialog, DialogActions, useTheme, useMediaQuery} from '@mui/material';
import '../App.css';

export const NewEditControl = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [showPage,setShowPage] = useState(0);

    const filter = createFilterOptions();

    const handleShow = (page) =>{
        setShowPage(page);
        props.setIdControl("0");
    }

    const close = () =>{
        setShowPage(0);
        props.onHide()
    }

    const commit = () =>{
        setShowPage(0);
        props.commit();
    }

    const deleteAndClose = () => {
        setShowPage(0);
        props.handleDeleteControl();
    }

    return(
        <>
            <ModalDelete
                show={props.showDeleteModalControl}
                onHide={props.handleDeleteCloseControl}
                handleDelete={deleteAndClose}/>
            <Dialog
                open={props.show}
                fullScreen={fullScreen}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth="true"
                maxWidth="sm"
                className="backgroundDialog"
            >
                <DialogTitle className="dialogTitle">
                    {
                        showPage === 0 ?
                            <h4>UI Variable</h4>
                        :
                            <></>
                    }
                    {
                        showPage === 1 ?
                            <>
                                <div>
                                    <h4 style={{display:"inline"}}>Add UI Variable</h4>
                                    <MdArrowBack style={{cursor:'pointer',fontSize:"1.5em",float:"right"}} onClick={(e)=> handleShow(0,e)}/>
                                </div>

                            </>
                        :
                            <></>
                    }
                    {
                        showPage === 2 ?
                            <div>
                                <h4 style={{display:"inline"}}>Delete UI Variable</h4>
                                <MdArrowBack style={{cursor:'pointer',fontSize:"1.5em",float:"right"}} onClick={(e)=> handleShow(0,e)}/>
                            </div>
                            :
                            <></>
                    }
                </DialogTitle>
                <DialogContent style={{display:"grid",textAlign:"center",marginTop:"10px"}}>
                    {
                        showPage === 0 ?
                            <div>
                                <Button style={{width:"40%"}} onClick={(e)=> handleShow(1,e)}>Add</Button>
                                <Button style={{width:"40%",marginLeft:"10px"}} onClick={(e)=> handleShow(2,e)}>Delete</Button>
                            </div>
                        :
                            <></>
                    }
                    {
                        showPage === 1 ?
                            <div>
                                <p>Name</p>
                                <Autocomplete
                                    value={props.control.name}
                                    onInputChange={props.handleChange}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue === option.name);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                inputValue,
                                                name: `Add "${inputValue}"`,
                                            });
                                        }

                                        return filtered;
                                    }}
                                    selectOnFocus
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={props.controls}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Regular option
                                        return option.name;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                    sx={{ width: 300 }}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} label="Variables shown already added!" />
                                    )}
                                />
                                <br/>
                                {
                                    /*<Form>
                                        <Form.Group >
                                            <p>Control</p>
                                            <Form.Control as="select" custom onChange={props.handleChange} name="content" value={props.idControlName}>
                                                <option value={""} name="control">No Control</option>
                                                {
                                                    props.controlName.map(control => (
                                                        <option key={control.id} value={control.id} name="control">{control.name}</option>
                                                    ))
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Form>*/
                                }
                                <div><Button style={{width:"40%"}} onClick={commit}>Add</Button></div>
                            </div>
                        :
                            <></>
                    }
                    {
                        showPage === 2 ?
                            <>
                                <Form>
                                    <Form.Group >
                                        <p>Control</p>
                                        <Form.Control as="select" custom onChange={props.handleSelectChange} name="content" value={props.idControl}>
                                            <option value={""} name="control">No Control</option>
                                            {
                                               props.controls.map(control => (
                                                    <option key={control.id} value={control.id} name="control">{control.name}</option>
                                                ))
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Form>
                                {
                                    props.idControl === "0" ?
                                        <></>
                                        :
                                        <>
                                            <p>Name</p>
                                            <input value={props.control.name} type="text" name="name" readOnly={true} disabled={true}/>
                                            <div>
                                                {
                                                    //<Button style={{width:"30%",marginTop:"10px",justifySelf:"center"}} onClick={commit}>Edit</Button>
                                                }
                                                <Button style={{width:"30%",marginTop:"10px", marginLeft:"10px",justifySelf:"center"}} onClick={props.delete}>Delete</Button>
                                            </div>
                                        </>
                                }
                            </>
                            :
                            <></>
                    }
                </DialogContent>
            </Dialog>
        </>
    )

}