import React from 'react';
import {AmplifyAuthenticator, AmplifySignIn} from "@aws-amplify/ui-react";

export const Authenticator = (props) => {

    return (
        <AmplifyAuthenticator>
            <AmplifySignIn slot="sign-in" hideSignUp></AmplifySignIn>
        </AmplifyAuthenticator>
    );
}