import React from 'react'
import {Button, Form} from "react-bootstrap";
import {DialogTitle, DialogContent, Dialog, DialogActions, useTheme, useMediaQuery} from '@mui/material';
import '../App.css';

export const NewEditMac = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const borderColor = {
        warning: {
            borderColor:"red"
        },
        default: {
        },
    };

    return(
        <>
            <Dialog
                open={props.show}
                fullScreen={fullScreen}
                onClose={props.onHide}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth="true"
                maxWidth="sm"
                className="backgroundDialog"
            >
                <DialogTitle className="dialogTitle">
                    {
                        props.id === "0" ? <h4>Add MAC</h4> : <h4>Edit MAC {props.device.mac}</h4>
                    }
                </DialogTitle>
                <DialogContent style={{display:"grid",textAlign:"center"}}>
                    <p>MAC</p>
                    {
                        props.authInfo.admin && props.authInfo.superUser || props.authInfo.admin && !props.authInfo.superUser || !props.authInfo.admin && props.authInfo.superUser?
                            <input value={props.device.mac} onChange={props.handleChange} style={props.errorDevice.mac === true ? {borderColor:"red"} : borderColor.default} type="text" name="mac"/>
                        :
                            <></>
                    }
                    {
                        !props.authInfo.admin && !props.authInfo.superUser ?
                            <input value={props.device.mac} onChange={props.handleChange} style={props.errorDevice.mac === true ? {borderColor:"red"} : borderColor.default} disabled={true} type="text" name="mac"/>
                            :
                            <></>
                    }
                    <p>Address</p>
                    <input value={props.device.address} onChange={props.handleChange} style={props.errorDevice.address === true ? {borderColor:"red"} : borderColor.default} type="text" name="address"/>
                    <p>Customers</p>
                    <input value={props.device.customer} onChange={props.handleChange} style={props.errorDevice.customer === true ? {borderColor:"red"} : borderColor.default} type="text" name="customer"/>
                    <p>Groups</p>
                    <input value={props.device.group} onChange={props.handleChange} style={props.errorDevice.group === true ? {borderColor:"red"} : borderColor.default} type="text" name="group"/>
                    <p>Type</p>
                    <input value={props.device.type} onChange={props.handleChange} style={props.errorDevice.type === true ? {borderColor:"red"} : borderColor.default} type="text" name="type"/>
                    <p>Description</p>
                    <input value={props.device.description} onChange={props.handleChange} type="text" name="description"/>
                    <Form>
                        <Form.Group >
                            <p>Content Package</p>
                            <Form.Control as="select" custom onChange={props.handleChange} name="content" value={props.device.content}>
                                <option value={""} name="content">No Content ID</option>
                                {
                                    props.contents.map(content => (
                                        <option key={content.id} value={content.id} name="content">{content.name}</option>
                                    ))
                                }
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button className="btn btn-secondary mr-auto" onClick={props.onHide}>Cancel</Button>
                    {
                        props.id === "0" ? <Button onClick={props.commit}>Create</Button> : <Button onClick={props.commit}>Edit</Button>
                    }
                </DialogActions>
            </Dialog>
        </>
    )

}