import React, {useEffect, useState} from 'react';
import './App.css';
import Amplify, {API, Auth, Hub, Storage} from 'aws-amplify';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import awsconfig from './aws-exports';
import {Authenticator} from "./components/authenticator";
import {TopBar} from "./components/topBar";
import AWS from 'aws-sdk';
import awsmobile from "./aws-exports";
import {NewEditUser} from "./components/newEditUser";
import {Loader} from "./components/loader";
import {ModalDelete} from "./components/modalDelete";
import {graphqlOperation} from "@aws-amplify/api-graphql";
import {
    getUser,
    listContents,
    listControls,
    listDevices,
    listRecords,
    listUsers
} from "./graphql/queries";
import {
    createUser,
    updateUser,
    deleteUser,
    createDevice,
    updateDevice,
    deleteDevice,
    createContent,
    createControl,
    deleteControl,
    createRecord,
    updateRecord,
    deleteRecord,
    deleteContent,
    batchAddDevice
} from "./graphql/mutations";
import {Collapse, Form} from "react-bootstrap";
import {Button} from '@material-ui/core'
import {MdEdit,MdComputer,MdDeleteForever,MdPhoto,MdFileDownload,MdViewList} from 'react-icons/md'
import {NewEditMac} from "./components/newEditMac";
import {NewContentFile} from "./components/newContentFile";
import {EditMultipleContents} from "./components/editMultipleContents"
import {NewEditContent} from "./components/newEditContent";
import {NewEditControl} from "./components/newEditControl";
import {NewEditRecord} from "./components/newEditRecord";
import {FilterTableComponent} from "./components/basicTable";
import {SelectionTableComponent} from "./components/selectionTable";
import {ModalPreview} from "./components/modalPreview";
import List from "@material-ui/core/List";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import {MdExitToApp} from "react-icons/md";
import ListItem from "@material-ui/core/ListItem";
import {MdAdd} from "react-icons/md/index";
import {BarChart,Devices,Room,TouchApp,InfoOutlined,ShowChart} from "@mui/icons-material"

AWS.config.update({
    region:awsmobile.aws_cognito_region,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey:process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
});

Amplify.configure(awsconfig);

const AuthStateApp = () => {
    const [showLoader,setLoader] = useState(false);
    const [showModal,setModal] = useState(false);
    const [showModalMac,setModalMac] = useState(false);
    const [showBtnContents,setBtnContents] = useState(false);
    const [showDeleteModal,setDeleteModal] = useState(false);
    const [showDeleteModalDevice,setDeleteModalDevice] = useState(false);
    const [errorDevice,setDeviceError] = useState({mac: false})
    const [showEditContents,setEditContents] = useState(false);
    const [showModalFile,setModalFile] = useState(false);
    const [showModalContent, setModalContent] = useState(false);
    const [showModalControl, setModalControl] = useState(false);
    const [showModalControlSuperUser,setModalControlSuperUser] = useState(false);
    const [showDeleteModalControl,setDeleteModalControl] = useState(false);
    const [showModalRecord, setModalRecord] = useState(false);
    const [showDeleteModalRecord,setDeleteModalRecord] = useState(false);
    const [showDeleteModalFile,setDeleteModalFile] = useState(false);
    const [showDeleteModalContent,setDeleteModalContent] = useState(false);
    const [showModalPreview,setShowPreview] = useState(false);
    const [columnsUser,setColumnsUser] = useState([]);
    const [columnsDevice,setColumnsDevice] = useState([]);
    const [columnsRecord,setColumnsRecord] = useState([]);

    const [authState, setAuthState] = useState();
    const [authUser, setAuthUser] = useState();
    const [authInfo, setAuthInfo] = useState({superUser:false});
    const [view,setView] = useState(0);

    const [idUser,setId] = useState("0");
    const [user,setUser] = useState({description:"",location:"",password:"",username:"",admin:false,group:""});
    const [users,setUsers] = useState([]);
    const [errorUser,setUserError] = useState({password:false,username:false});

    const [selectDevices,setSelectDevices] = useState([]);
    const [devices,setDevices] = useState([]);
    const [device,setDevice] = useState({mac:"",address:"",customer:"",group:"",type:"",description:"",content:""})
    const [idDevice,setIdDevice] = useState("0");

    const [idContent,setIdContent] = useState("0");
    const [content,setContent] = useState({name:""});
    const [selectContents,setSelectContents] = useState([]);
    const [showSelectedContent,setShowSelectedContent] = useState("0");

    const [idControl,setIdControl] = useState("0");
    const [control,setControl] = useState({name:""});
    const [controls,setControls] = useState([]);

    const [idRecord,setIdRecord] = useState("0");
    const [records,setRecords] = useState([]);
    const [record,setRecord] = useState({startDate:"",endDate:"",Mo:true,Tu:true,We:true,Th:true,Fr:true,Sa:true,Su:true,startTime:"",endTime:"",fileId:"",controlId:""});

    const [files,setFiles] = useState([]);
    const [idFile,setIdFile] = useState("0");
    const [file,setFile] = useState(null);
    const [fileError,setFileError] = useState(false);
    const [url,setUrl] = useState("");
    const [format,setFormat] = useState("");

    const [historyArray,setHistoryArray] = useState([]);
    const [limitObj,setLimitObj] = useState({label:"0",value:"0"})
    const [limitUplaod,setLimitUpload] = useState({label:"0",value:"0"})

    const heightTable = 100;
    const monthlyTrafficLimit = 250;
    const limitDataUpload = 1000;

    const columnsFile = React.useMemo(
        () => [
            {
                Header: 'File Name',
                accessor: row => row.key.split("/")[(row.key.split("/")).length-1],
            },
            {
                Header: 'Creation Date',
                accessor: row => ("0" + new Date(row.lastModified).getDate()).slice(-2)+"/"+
                ("0" + (new Date(row.lastModified).getMonth() + 1)).slice(-2)+"/"+
                new Date(row.lastModified).getFullYear()+" "+
                ("0" + new Date(row.lastModified).getHours()).slice(-2)+":"+
                ("0" + new Date(row.lastModified).getMinutes()).slice(-2)
            },
            {
                Header: 'Size',
                accessor: 'size'
            },
            {
                Header: '',
                accessor: 'btn',
                Cell: ({cell}) => (
                    <div style={{display:"flex",justifyContent:"center"}}>
                        <MdDeleteForever style={{cursor:'pointer',fontSize:"1.5em"}} onClick={(e) => handleShowDeleteFile(cell.row.original.key,e)}/>
                        <MdPhoto style={{cursor:'pointer',fontSize:"1.5em"}} onClick={(e)=> showPreview(cell.row.original.key,e)}/>
                        <MdFileDownload  style={{cursor:'pointer',fontSize:"1.5em"}} onClick={(e) => handleDownloadFile(cell.row.original.key,e)}/>
                    </div>
                )
            },
        ],
        []
    )

    const columnsHistory = React.useMemo(
        () => [
            {
                Header: 'Number',
                accessor: 'number',
            },
            {
                Header: 'Content Package',
                accessor: 'content'
            },
            {
                Header: 'Load',
                accessor: 'load'
            },
            {
                Header: 'Date',
                accessor: 'date'
            },
            {
                Header: 'Traffic',
                accessor: 'traffic'
            },
        ],
        []
    )

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setLoader(true);
            setAuthState(nextAuthState);
            setAuthUser(authData);
            if(authData){
                if(authData.signInUserSession){
                    //user info
                    API.graphql(graphqlOperation(getUser,{id: authData.attributes.sub})).then((response) => {
                        const info = response.data.getUser;
                        if(info===null){
                            setLoader(false);
                            return;
                        }
                        setAuthInfo(info);
                        let array = [{
                            Header: 'Email',
                            accessor: 'username',
                        }];
                        if(info.superUser) {
                            array.push({
                                Header: 'Group',
                                accessor: 'group'
                            });
                        }
                        array.push({
                                Header: 'Description',
                                accessor: 'description'
                            },
                            {
                                Header: 'Location',
                                accessor: 'location'
                            },
                            {
                                Header: 'Id',
                                accessor: 'id'
                            },
                            {
                                Header:"",
                                disableFilters:true,
                                accessor:"btn",
                                Cell: ({cell}) => (
                                    <div>
                                        <MdEdit style={{cursor:'pointer',fontSize:"1.5em"}} onClick={(e)=> handleEdit(cell.row.original,e)}/>
                                        <MdComputer style={{cursor:'pointer',marginLeft:"5px",marginRight:"5px",fontSize:"1.5em"}} onClick={(e)=> showMAC(cell.row.original,e)}/>
                                        <MdDeleteForever style={{cursor:'pointer',fontSize:"1.5em"}} onClick={(e) => handleShowDelete(cell.row.original.id,e)}/>
                                    </div>
                                )
                            });
                        setColumnsUser(array);
                        if(info.superUser || info.admin){
                            setView(1);
                            getUsers(info);
                        }
                        else{
                            setUser(info);
                            setLoader(false);
                            setId(info.id);
                            setView(2);
                            getDevices(info.id)
                            getSelectContents(info.id);
                        }
                    })
                    .catch((err) =>{
                        setLoader(false);
                        console.log('error info',err);
                    });
                }
            }
            setView(0);
            setUsers([]);
            setDevices([]);
            setLoader(false);
            setUser({description:"",location:"",password:"",username:"",admin:false,group:""});
        });
    }, []);

    const getUsers = (info) => {
        setLoader(true);
        if(info.superUser){
            //get all user
            API.graphql(graphqlOperation(listUsers)).then((response) =>{
                const userList = response.data.listUsers.items;
                setLoader(false);
                setUsers(userList);
            })
                .catch((err)=>{
                    setLoader(false);
                    console.log('error list',err);
                })
        }
        else if(info.admin)
        {
            //user in group
            API.graphql(graphqlOperation(listUsers,{
                filter:{
                    group: {
                        eq:info.group
                    }
                }
            })).then((response) =>{
                const userList = response.data.listUsers.items;
                setLoader(false);
                setUsers(userList);
            })
                .catch((err)=>{
                    setLoader(false);
                    console.log('error list',err);
                })
        }
    }

    const handleModal = () =>{
        setModal(true);
        setUser({description:"",location:"",password:"",username:"",admin:false,group:""});
        setId("0");
        setUserError({password:false,username:false});
    }
    const handleClose = () => {
        setModal(false);
    }

    const handleChange = e => {
        const {name,value} = e.target;
        setUser(prevState => ({
            ...prevState,
            [name]:value
        }));
    }

    const handleSelect = (id,e) => {
        const {name,checked} = e.target;
        const updateCheckedItems = {...user,[name]:checked };
        setUser(updateCheckedItems);
    }


    const onUser = () => {
        setLoader(true);
        let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
        if(idUser==="0"){
            //add user
            if(user.password.length < 8){
                setUserError({password:true,username:false})
                setLoader(false);
                return;
            }
            let params = {
                UserPoolId: awsmobile.aws_user_pools_id,
                Username:user.username,
                TemporaryPassword:user.password,
                UserAttributes: [
                    {
                        Name:'email',
                        Value:user.username
                    },
                    {
                        Name:'email_verified',
                        Value:'true'
                    }
                ]
            };
            cognitoidentityserviceprovider.adminCreateUser(params, function (err,response) {
                if (err) {
                    //todo mostrare errore
                    console.log(err)
                    setLoader(false);
                }
                if (response) {
                    let group = user.group === "" ? authInfo.group : user.group;
                    const data = {
                        username:user.username,
                        description:user.description,
                        location:user.location,
                        admin:user.admin,
                        group:group,
                        id:response.User.Attributes[0].Value,
                        superUser:false
                    };
                    API.graphql(graphqlOperation(createUser,{input:data})).then((data) => {
                        setModal(false);
                        getUsers(authInfo);
                    }).catch((err) => {
                        console.log('err',err);
                        setLoader(false);
                        //todo delete dell utente perche registrazione non avvenuta con successo
                    });
                }
            });
        }
        else{
            let data = {
                description:user.description,
                location:user.location,
                admin:user.admin,
                id:idUser,
            };
            if(authInfo.superUser){
                data = {
                    description:user.description,
                    location:user.location,
                    admin:user.admin,
                    group:user.group,
                    id:idUser,
                };
            }

            API.graphql(graphqlOperation(updateUser,{input:data})).then((data) => {
                setModal(false);
                getUsers(authInfo);
            }).catch((err) => {
                console.log('err',err);
                setLoader(false);
            });
        }
    }

    const handleEdit = (user,e) => {
        setId(user.id);
        setUser({
            username: user.username,
            password: "",
            admin:user.admin,
            group:user.group,
            description: user.description,
            location: user.location
        });
        setModal(true);
    }

    const showMAC = (usersSel,e) => {
        setId(usersSel.id);
        setUser(usersSel);
        setView(2);
        setLoader(true);
        getDevices(usersSel.id);
        getSelectContents(usersSel.id);
    }

    const closeMac = () => {
        setView(1);
        getUsers(authInfo);
        setHistoryArray([]);
        setDevices([]);
    }

    const handleShowDelete = (id,e) => {
        setId(id);
        setDeleteModal(true);
    }

    const handleDeleteClose = () => {setDeleteModal(false);}
    const handleDelete = e => {
        setLoader(true);
        deleteUserComplete(idUser);
    }

    const deleteUserComplete = (id) => {
        let index = users.findIndex(obj => obj.id === id);
        let params = {
            UserPoolId: awsmobile.aws_user_pools_id,
            Username:users[index].username
        };

        let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
        cognitoidentityserviceprovider.adminDeleteUser(params, function(err, data) {
            setLoader(false);
            if (err) {
                console.log(err, err.stack);
                setLoader(false);
            }
            else {
                const data = {
                    id:idUser,
                }
                API.graphql(graphqlOperation(deleteUser,{input:data})).then((data) => {
                    handleDeleteClose();
                    getUsers(authInfo);
                }).catch((err) => {
                    console.log('err',err);
                    setLoader(false);
                });
            }
        });
    }

    const getDevices = (userId) => {
        setLoader(true);
        API.graphql(graphqlOperation(listDevices,{
            filter:{
                user:{
                    eq:userId
                }
            }
        })).then((response) => {
            const devicesList = response.data.listDevices.items;
            setLoader(false);
            setDevices(devicesList);
        })
        .catch((err) =>{
            setLoader(false);
            console.log('error devices ',err);
        });
    }

    const handleDevice = () => {
        setIdDevice("0");
        setDevice({mac:"",address:"",customer:"",group:"",type:"",description:"",content:""})
        setModalMac(true);
    }

    const handleMacClose = () => {
        setModalMac(false);
        setDeviceError({mac: false});
    };

    const handleChangeDevice = e => {
        const {name,value} = e.target;
        setDevice(prevState => ({
            ...prevState,
            [name]:value
        }));
    }

    const handleSelectDevice = (arrayObj) => {
        let newArray = [];
        arrayObj.forEach(obj =>{
            newArray.push(obj.original);
        })
        //setSelectDevices(arrayObj);
        setSelectDevices(newArray);
        if(arrayObj.length > 1){
            setBtnContents(true);
            setHistoryArray([]);
            setLimitObj({label:0,value:0});
        }
        else if(arrayObj.length === 1){
            setBtnContents(false);
            getHistory(arrayObj[0].original.id);
        }
        else{
            setBtnContents(false);
            setHistoryArray([]);
            setLimitObj({label:0,value:0});
        }
    }

    useEffect( () =>{
        let varName = "";
        if(user.username !== authInfo.username){
            if(user.username !== ""){
                varName = " ("+user.username+")";
            }
        }
        setColumnsDevice([
            {
                Header: 'Mac'+varName,
                accessor: 'mac',
            },
            {
                Header: 'Address',
                accessor: 'address'
            },
            {
                Header: 'Customer',
                accessor: 'customer'
            },
            {
                Header: 'Group',
                accessor: 'group'
            },
            {
                Header: 'Type',
                accessor: 'type'
            },
            {
                Header: 'Content Package',
                accessor: row => selectContents.findIndex(obj => obj.id === row.content) !== -1 ?
                    selectContents[selectContents.findIndex(obj => obj.id === row.content)].name
                    :
                    "",
            },
            {
                Header:"",
                disableFilters:true,
                accessor:"btn",
                Cell: ({cell}) => (
                    <div>
                        <MdEdit style={{cursor:'pointer',fontSize:"1.5em"}} onClick={(e)=> handleEditDevice(cell.row.original,e)}/>
                        <MdViewList style={{cursor:'pointer',marginLeft:"5px",marginRight:"5px",fontSize:"1.5em"}} onClick={(e)=> showContent(cell.row.original.content,e)}/>
                        <MdDeleteForever style={{cursor:'pointer',fontSize:"1.5em"}} onClick={(e) => handleShowDeleteDevice(cell.row.original.id,e)}/>
                    </div>
                )
            }
        ]);
    }, [selectContents]);

    const getKeyByValue = (object, value) => {
        return Object.keys(object).find(key => object[key] === value);
    }

    const getHistory = (id) => {
        setLoader(true);
        let index = devices.findIndex(obj => obj.id === id);
        let mac = devices[index].mac;
        let s3PathHistory = mac + "/history.csv";
        let user = devices[index].user;
        let indexContent = selectContents.findIndex(obj => obj.id === devices[index].content)
        if(indexContent !== -1){
            let contentName = selectContents[indexContent].name+".lst";
            let s3PathContent = user+"/"+contentName;
            Storage.list(s3PathContent).then((files) => {
                let timestamp = "";
                let fileInfo = files[0];
                if(fileInfo.key === s3PathContent){
                    timestamp = new Date(fileInfo.lastModified).getFullYear()+"/"+
                        ("0" + (new Date(fileInfo.lastModified).getMonth() + 1)).slice(-2)+"/"+
                        ("0" + new Date(fileInfo.lastModified).getDate()).slice(-2)+" "+
                        ("0" + new Date(fileInfo.lastModified).getHours()).slice(-2)+":"+
                        ("0" + new Date(fileInfo.lastModified).getMinutes()).slice(-2)+":"+
                        ("0" + new Date(fileInfo.lastModified).getSeconds()).slice(-2)
                    getFileHistory(s3PathHistory,timestamp,contentName);
                }
            })
        }
        else{
            getFileHistory(s3PathHistory,"","");
        }
    }

    const getFileHistory = (s3PathHistory,timestamp,contentName) =>{
        Storage.get(s3PathHistory,{
            download:true,
            cacheControl: 'no-cache'
        }).then((downloadFile) => {
            downloadFile.Body.text().then(text =>{
                let lines = text.split("\n");
                let count = 0;
                let temp = [];
                if(lines.length>2){
                    for(let line = 1; line < lines.length; line ++){
                        let info = lines[line].split("|");
                        if(info.length > 1) {
                            count++;
                            if(count === 1 && timestamp !== ""){
                                let checksum = info[4].replace(/(\r\n|\n|\r)/gm, "");
                                if(timestamp !== checksum){
                                    let obj = {
                                        number: count,
                                        content:contentName,
                                        load:"No",
                                        date:timestamp,
                                        traffic:""
                                    }
                                    temp.push(obj);
                                    count++;
                                }
                            }
                            else if(count === 1 && timestamp === ""){
                                let obj = {
                                    number: count,
                                    content:contentName,
                                    load:"No",
                                    date:timestamp,
                                    traffic:""
                                }
                                temp.push(obj);
                                count++;
                            }
                            let obj = {
                                number: count,
                                content:info[0],
                                load:info[1] === "" ? "No" : "Yes",
                                date:info[2],
                                traffic:info[3]
                            }
                            temp.push(obj);
                        }
                    }
                }
                else{
                    count++;
                    let obj = {
                        number: count,
                        content:contentName,
                        load:"No",
                        date:timestamp,
                        traffic:""
                    }
                    temp.push(obj);
                }
                setHistoryArray(temp);
                getLimit(temp);
                setLoader(false);
            })
        })
        .catch((err) =>{
            console.log(err);
            setLoader(false);
        })
    }

    const getLimit = (historyArray) => {
        let dateObj = new Date();
        let currentYear = dateObj.getFullYear();
        let currentMonth = dateObj.getMonth() + 1;
        let trafficByte = 0;
        let trafficMb = 0;
        let trafficPerc = 0;
        historyArray.forEach(history =>{
            if(history.traffic !== ""){
                let date = history.date.split("/");
                if(currentYear === parseInt(date[0]) && currentMonth === parseInt(date[1])){
                    trafficByte += parseInt(history.traffic);
                }
            }
        })
        if(trafficByte !== 0){
            trafficMb = parseInt(trafficByte) / 1000000;

            if(trafficMb > monthlyTrafficLimit) {
                trafficPerc = 100;
            }
            else{
                trafficPerc = (trafficMb / monthlyTrafficLimit) * 100;
            }
        }
        setLimitObj({label:Math.trunc(trafficMb),value:trafficPerc});
    }

    const onDevice = () => {
        setLoader(true);
        if(idDevice === "0"){
            const data = {
                mac:device.mac,
                user:idUser,
                address: device.address,
                content: device.content,
                customer: device.customer,
                description: device.description,
                group: device.group,
                type: device.type,
            };
            API.graphql(graphqlOperation(createDevice,{input:data})).then((data) => {
                createFileListDevice(idUser,device);
            }).catch((err) => {
                console.log('err',err);
                setLoader(false);
            });
        }
        else{
            const data = {
                id: idDevice,
                mac: device.mac,
                user: idUser,
                address: device.address,
                content: device.content,
                customer: device.customer,
                description: device.description,
                group: device.group,
                type: device.type,
            };
            API.graphql(graphqlOperation(updateDevice,{input:data})).then((data) => {
                createFileListDevice(idUser,device);
            }).catch((err) => {
                console.log('err',err);
                setLoader(false);
            });
        }
    }

    const createFileListDevice = (userId,lastDevice) => {
        API.graphql(graphqlOperation(listDevices,{
            filter:{
                user:{
                    eq:userId
                }
            }
        })).then((response) => {
            setDevices(response.data.listDevices.items);
            createFilePathContent(lastDevice);
        })
            .catch((err) =>{
                setLoader(false);
                console.log('error devices ',err);
            });
    }

    const createFilePathContent = (deviceInfo) => {
        let text = "FilePath";
        let index = selectContents.findIndex(obj => obj.id === deviceInfo.content);
        if(index !== -1){
            text += "\n" + idUser + "/" + selectContents[index].name + ".lst";
        }

        let s3Path = deviceInfo.mac+"/fcontent.csv";
        const file = new Blob([text],
            {type:"text/plain;charset=utf-8"});
        Storage.put(s3Path,file).then(() =>{
            setLoader(false);
            setModalMac(false);
            createFileHistory(deviceInfo);
            console.log("Uploaded fc");
        })
        .catch((err)=>{
            console.log("upload err ",err);
            setLoader(false);
        })
    }

    const createFileHistory = (deviceInfo) => {
        let s3Path = deviceInfo.mac + "/history.csv";
        Storage.list(deviceInfo.mac).then((files) => {
            let exist = false;
            files.forEach(fileInfo =>{
                if(fileInfo.key === deviceInfo.mac+"/history.csv"){
                    exist = true;
                }
            })
            let text = "";
            if(!exist) {
                text = "FilePath|Downloaded|Date|Traffic";
                const file = new Blob([text],
                    {type:"text/plain;charset=utf-8"});
                Storage.put(s3Path,file).then((data) =>{
                    console.log(data);
                })
                .catch((err) =>{
                    console.log("err history",err);
                })
            }
        })
    }

    const handleEditDevice = (device,e) => {
        setIdDevice(device.id);
        setDevice({description: device.description,mac:device.mac,address:device.address,
            customer:device.customer,group:device.group,
            type:device.type,content:device.content});
        setModalMac(true)
    }

    const handleShowDeleteDevice = (id,e) => {
        setIdDevice(id);
        setDeleteModalDevice(true);
    }

    const handleDeleteCloseDevice = () => {setDeleteModalDevice(false);}
    const handleDeleteDevice  = e => {
        setLoader(true);
        const data = {
            id:idDevice,
        }
        API.graphql(graphqlOperation(deleteDevice,{input:data})).then((data) => {
            console.log('success',data);
            handleDeleteCloseDevice();
            getDevices(idUser);
        }).catch((err) => {
            console.log('err',err);
            setLoader(false);
        });
    }

    const showContent = (id,e) => {
        clearAll();
        getSelectControls(user.group);
        if(id===""){
            setView(3);
            setShowSelectedContent("0");
            setRecords([]);
        }
        else{
            setView(3);
            setShowSelectedContent(id);
            getFiles();
        }
    }
    useEffect(() => {
        if(showSelectedContent !== "0"){
            getRecordList(showSelectedContent);
        }
    },[files]);

    const showFiles = () => {
        setView(4);
        getFiles();
    }

    useEffect( () =>{
        setColumnsRecord([
                {
                    Header: 'Start Date',
                    accessor: row => row.startDate !== "" ? ("0" + new Date(row.startDate).getDate()).slice(-2)+"/"+
                        ("0" + (new Date(row.startDate).getMonth() + 1)).slice(-2)+"/"+
                        new Date(row.startDate).getFullYear() : "",
                },
                {
                    Header: 'End Date',
                    accessor: row => row.endDate!== "" ? ("0" + new Date(row.endDate).getDate()).slice(-2)+"/"+
                        ("0" + (new Date(row.endDate).getMonth() + 1)).slice(-2)+"/"+
                        new Date(row.endDate).getFullYear() : ""
                },
                {
                    Header: 'Su',
                    accessor: 'Su',
                    Cell: ({cell}) => (
                        <input type="checkbox" checked={cell.row.original.Su || false} readOnly={true}/>
                    ),
                    disableFilters: true
                },
                {
                    Header: 'Mo',
                    accessor: 'Mo',
                    Cell: ({cell}) => (
                        <input type="checkbox" checked={cell.row.original.Mo || false} readOnly={true}/>
                    ),
                    disableFilters: true
                },
                {
                    Header: 'Tu',
                    accessor: 'Tu',
                    Cell: ({cell}) => (
                        <input type="checkbox" checked={cell.row.original.Tu || false} readOnly={true}/>
                    ),
                    disableFilters: true
                },
                {
                    Header: 'We',
                    accessor: 'We',
                    Cell: ({cell}) => (
                        <input type="checkbox" checked={cell.row.original.We || false} readOnly={true}/>
                    ),
                    disableFilters: true
                },
                {
                    Header: 'Th',
                    accessor: 'Th',
                    Cell: ({cell}) => (
                        <input type="checkbox" checked={cell.row.original.Th || false} readOnly={true}/>
                    ),
                    disableFilters: true
                },
                {
                    Header: 'Fr',
                    accessor: 'Fr',
                    Cell: ({cell}) => (
                        <input type="checkbox" checked={cell.row.original.Fr || false} readOnly={true}/>
                    ),
                    disableFilters: true
                },
                {
                    Header: 'Sa',
                    accessor: 'Sa',
                    Cell: ({cell}) => (
                        <input type="checkbox" checked={cell.row.original.Sa || false} readOnly={true}/>
                    ),
                    disableFilters: true
                },
                {
                    Header: 'Start Time',
                    accessor: row => row.startTime !== "" ? ("0" + new Date(row.startTime).getHours()).slice(-2)+":"+
                        ("0" + new Date(row.startTime).getMinutes()).slice(-2) : ""
                },
                {
                    Header: 'End Time',
                    accessor: row => row.endTime !== "" ? ("0" + new Date(row.endTime).getHours()).slice(-2)+":"+
                        ("0" + new Date(row.endTime).getMinutes()).slice(-2) : ""
                },
                {
                    Header: 'File Name',
                    accessor: row => files.findIndex(obj => obj.key === row.fileId) !== -1 ?
                        (files[files.findIndex(obj => obj.key === row.fileId)].key).split("/")[((files[files.findIndex(obj => obj.key === row.fileId)].key).split("/")).length-1]
                        :
                        ""
                },
                {
                    Header: "UI Variable",
                    accessor: row => controls.findIndex(obj => obj.id === row.controlId) !== -1 ?
                        controls[controls.findIndex(obj => obj.id === row.controlId)].name
                        :
                        ""
                },
                {
                    Header: '',
                    accessor: 'btn',
                    Cell: ({cell}) => (
                        <div>
                            <MdEdit style={{cursor:'pointer',fontSize:"1.5em"}} onClick={(e)=>  handleEditRecord(cell.row.original,e)}/>
                            <MdPhoto style={{cursor:'pointer',fontSize:"1.5em"}} onClick={(e)=> showPreview(cell.row.original.fileId,e)}/>
                            <MdDeleteForever style={{cursor:'pointer',fontSize:"1.5em"}} onClick={(e) => handleShowDeleteRecord(cell.row.original.id,e)}/>
                        </div>
                    )
                },
            ]
        )
    }, [records]);

    const handleFileClose = () => {setModalFile(false);};
    const handleNewFile = () => {
        setIdFile("0");
        setFile(null)
        setFileError(false);
        setModalFile(true);
    }

    const handleChangeFile = e => {
        if(e.target.files.length > 0){
            setFile(e.target.files[0]);
            setFileError(false);
        }
        else{
            setFile(null);
            setFileError(true);
        }
    }

    const onFile = async () => {
        setLoader(true);
        if(file === null){
            setLoader(false);
            setFileError(true);
        }
        else{
            let s3Path = idUser + "/" + file.name;
            if(file.name.split(".")[(file.name.split(".")).length-1] === "pdf"){
                Storage.put(s3Path,file, {
                    contentType: 'application/pdf'
                }).then(() =>{
                    console.log("uploaded");
                    setModalFile(false);
                    setLoader(false);
                    getFiles();
                })
                    .catch((err) =>{
                        console.log("err");
                        setLoader(false);
                    })
            }
            else{
                Storage.put(s3Path,file).then(() =>{
                    console.log("uploaded");
                    setModalFile(false);
                    setLoader(false);
                    getFiles();
                })
                    .catch((err) =>{
                        console.log("err");
                        setLoader(false);
                    })
            }
        }
    }

    const getFiles = () =>{
        setLoader(true);
        let s3Path = idUser;
        Storage.list(s3Path).then((files) => {
            let temp = [];
            let uploadByte = 0;
            let uploadMb = 0;
            let uploadPerc = 0;
            files.forEach(fileInfo =>{
                let extension = fileInfo.key.split(".");
                extension = extension[extension.length-1];
                if(extension !== "lst" && extension !== "csv"){
                    temp.push(fileInfo);
                }
                uploadByte += parseInt(fileInfo.size);
            })
            setFiles(temp);
            if(uploadByte !== 0){
                uploadMb = parseInt(uploadByte) / 1000000;
                if(uploadMb > limitDataUpload) {
                    uploadPerc = 100;
                }
                else{
                    uploadPerc = (uploadMb / limitDataUpload) * 100;
                }
            }
            setLimitUpload({label:Math.trunc(uploadMb),value:uploadPerc});
            setLoader(false);
        })
        .catch((err) =>{
            console.log("err");
            setLoader(false);
        })
    }

    const handleShowDeleteFile = (idFile,e) => {
        setIdFile(idFile);
        setDeleteModalFile(true);
    }

    const handleDownloadFile = (idFile,e) => {
        Storage.get(idFile,{
            download:true,
            cacheControl: 'no-cache'
        }).then((file) => {
            let filename = idFile.split("/")[(idFile.split("/")).length-1]
            downloadBlob(file.Body,filename)
        })
    }

    const downloadBlob = (file,filename) => {
        const url = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener('click', clickHandler);
            }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();
        return a;
    }

    const handleDeleteCloseFile = () => {
        setIdFile("0");
        setDeleteModalFile(false);
    }

    const handleDeleteCloseContent = () => {
        setDeleteModalContent(false);
    }

    const handlePreviewCloseContent = () => {
        setShowPreview(false);
    }

    const handleDeleteContent = () => {
        let index = selectContents.findIndex(obj => obj.id === showSelectedContent);
        let contentName = selectContents[index].name;
        let s3Path = idUser + "/" + contentName +".lst";
        Storage.remove(s3Path);

        const data = {
            id:showSelectedContent,
        }
        API.graphql(graphqlOperation(deleteContent,{input:data})).then((data) => {
            removeRecords();
            updateContentDevices();
            getSelectContents(idUser);
            setShowSelectedContent("0");
            setRecords([]);
            setDeleteModalContent(false);
        }).catch((err) => {
            console.log('err',err);
            setLoader(false);
        });
    }

    const removeRecords = async () => {
        const docClient = new AWS.DynamoDB.DocumentClient({apiVersion: '2012-08-10'});
        const TABLE_NAME = "Record-jqggrcssqjhqpoeswgljt22qty-dev";
        let leftItems = records.length;
        let group = [];
        let groupNumber = 0;

        for (const i of records) {
            const deleteReq = {DeleteRequest: {Key: {},},};
            deleteReq.DeleteRequest.Key["id"] = i["id"];
            group.push(deleteReq);
            leftItems--;

            if (group.length === 25 || leftItems < 1) {
                groupNumber++;

                console.log(`Batch ${groupNumber} to be deleted.`);

                const params = {
                    RequestItems: {
                        [TABLE_NAME]: group,
                    },
                };

                await docClient.batchWrite(params).promise();

                console.log(
                    `Batch ${groupNumber} processed. Left items: ${leftItems}`
                );

                // reset
                group = [];
            }
        }
    }

    const updateContentDevices = async () => {
        let ddb = new AWS.DynamoDB({apiVersion: '2012-08-10'});

        const params = {
            // Specify which items in the results are returned.
            FilterExpression: "content = :s",
            // Define the expression attribute value, which are substitutes for the values you want to compare.
            ExpressionAttributeValues: {
                ":s": {S: showSelectedContent},
            },
            TableName : "Device-xz5qpzjnlbafjcc36pwldmiqx4-dev",
            ProjectionExpression: "id",
        };

        ddb.scan(params, function(err, data) {
            if (err) {
                console.log("Error", err);
            } else {
                data.Items.forEach(function(element, index, array) {
                    const data = {
                        id: element.id.S,
                        content: "",
                    };
                    API.graphql(graphqlOperation(updateDevice,{input:data}));
                });
            }
        });
    }

    const handleDeleteFile = () => {
        Storage.remove(idFile).then(() => {
            handleDeleteCloseFile();
            getFiles();
        })
    }

    const handleModalContentClose = () => {setModalContent(false)};
    const handleNewContent = () => {
        setIdContent("0");
        setContent({name:""});
        setModalContent(true);
    }

    const handleChangeContent = e => {
        const {name,value} = e.target;
        setContent(prevState => ({
            ...prevState,
            [name]:value
        }));
    }

    const onContent = () => {
        setLoader(true);
        if(idContent === "0"){
            const data = {
                name:content.name,
                user:idUser
            };
            API.graphql(graphqlOperation(createContent,{input:data})).then((data) => {
                const file = new Blob(["DateStart|DateStop|Monday|Thursday|Wednesday|Tuesday|Friday|Saturday|Sunday|TimeStart|TimeStop|FileName|NameField"],
                    {type:"text/plain;charset=utf-8"});
                let contentName = data.data.createContent.name;
                let s3Path = idUser + "/" + contentName +".lst";
                Storage.put(s3Path,file).then(() =>{
                    setModalContent(false);
                    getSelectContents(idUser);
                    console.log("Uploaded");
                })
                .catch((err)=>{
                    console.log("upload err ",err);
                    setLoader(false);
                })
            }).catch((err) => {
                console.log('err',err);
                setLoader(false);
            });
        }
    }

    const handleShowDeleteContent = () => {
        setDeleteModalContent(true);
    }

    const closeContent = () => {
        setView(2);
        setRecords([])
        getDevices(idUser);
    }

    const handleContentsClose = () => {
        setEditContents(false)
    }

    const handleChangeIdContent = e => {
        const {name,value} = e.target;
        setIdContent(value);
    }

    const getSelectContents = (userId) => {
        setLoader(true);
        API.graphql(graphqlOperation(listContents,{
            filter:{
                user:{
                    eq:userId
                }
            }
        })).then((response) => {
            const contentsList = response.data.listContents.items;
            setLoader(false);
            setSelectContents(contentsList);
        })
        .catch((err) =>{
            setLoader(false);
            console.log('error devices ',err);
        });
    }

    const changeContent = (e) => {
        setShowSelectedContent(e.target.value);
        getFiles();
    }

    const handleChangeName = (e,newValue) => {
        if (typeof newValue === 'string') {
            setControl({
                name: newValue,
            });
        } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setControl({
                name: newValue.inputValue,
            });
        } else if(newValue !== null){
            setControl(newValue);
        }
    }

    const handleSelectChangeControl = (e) => {
        if(e.target.value === ""){
            setIdControl(e.target.value);
        }
        else{
            setIdControl(e.target.value);
            let index = controls.findIndex(obj => obj.id === e.target.value);
            setControl({name:controls[index].name})
        }
    }

    const showModalDeleteControl = () => {
        setDeleteModalControl(true);
        setModalControl(false);
    }

    const setIdControlDefault = () =>{
        setIdControl("0");
        setControl({name: ""});
    }

    function compare( a, b ) {
        if ( a.name < b.name ){
            return -1;
        }
        if ( a.name > b.name ){
            return 1;
        }
        return 0;
    }

    const getSelectControls = (groupName) =>{
        setLoader(true);
        API.graphql(graphqlOperation(listControls,{
            filter:{
                group:{
                    eq:groupName
                }
            }
        })).then((response) => {
            const controlsList = response.data.listControls.items;
            controlsList.sort(compare);
            setLoader(false);
            setControls(controlsList);
        })
            .catch((err) =>{
                setLoader(false);
                console.log('error devices ',err);
            });
    }

    const onControl = () =>{
        setLoader(true);
        let controlNameTrim = control.name.trim();
        if(controlNameTrim !== ""){
            let findControl = controls.filter(obj => {
                return obj.name === controlNameTrim;
            })
            if(findControl.length === 0) {
                if (idControl === "0") {
                    const data = {
                        name: controlNameTrim,
                        group: user.group
                    };
                    API.graphql(graphqlOperation(createControl, {input: data})).then((data) => {
                        setModalControl(false);
                        setIdControl("0");
                        setControl({name: ""});
                        getSelectControls(user.group);
                    }).catch((err) => {
                        console.log('err', err);
                        setLoader(false);
                    });
                }
            }
            else{
                setLoader(false);
            }
        }
        else{
            setLoader(false);
        }
    }


    const updateMultipleContents = (e) => {
        setLoader(true);
        let leftItems = selectDevices.length;
        let group = [];
        let groupNumber = 0;

        selectDevices.forEach(devices =>{
            devices.content = idContent;
            delete devices.updatedAt;
            group.push(devices);
            leftItems--;
            if(group.length === 25 || leftItems < 1){
                groupNumber++;
                console.log(`Batch ${groupNumber} to be updated.`);
                API.graphql(graphqlOperation(batchAddDevice, {input: group})).then((data) => {
                    if(leftItems < 1){
                        handleContentsClose();
                        getDevices(idUser);
                    }
                }).catch((err) => {
                    console.log('err', err);
                    setLoader(false);
                });
                group = [];
            }
        })
    }

    const clearAll = () => {
        //console.log(filtered);
    }

    const handleModalControlClose = () =>{
        setModalControl(false);
    }

    const handleModalControlSuperClose = () =>{
        setModalControlSuperUser(false);
    }

    const handleNewControl = () =>{
        setModalControl(true);
        setControl({name:""});
    }

    const handleDeleteCloseControl = () => {
        setDeleteModalControl(false);
        setModalControl(true);
    }

    const handleDeleteControl = () => {
        setLoader(true);
        /*const data = {
            id:idControl,
        }*/
        const data = {
            id:idControl,
        }
        API.graphql(graphqlOperation(deleteControl,{input:data})).then((data) => {
            setDeleteModalControl(false);
            setModalControl(false);
            setIdControl("0");
            setControl({name:""});
            getSelectControls(user.group);
        }).catch((err) => {
            console.log('err',err);
            setLoader(false);
        });
    }

    const handleModalRecordClose = () =>{
        setModalRecord(false);
    }

    const handleNewRecord = () => {
        setModalRecord(true);
        setRecord({startDate:"",endDate:"",Mo:true,Tu:true,We:true,Th:true,Fr:true,Sa:true,Su:true,startTime:"",endTime:"",file:"",control:""});
        setIdRecord("0");
    }

    const handleChangeRecord = e => {
        const {name,value} = e.target;
        setRecord(prevState => ({
            ...prevState,
            [name]:value
        }));
    }

    const handleCheckRecord = (id,e) => {
        const {name,checked} = e.target;
        const updateCheckedItems = {...record,[name]:checked };
        setRecord(updateCheckedItems);
    }

    const handleChangeDate = (date,name) =>{
        if(date === null){
            date = "";
        }
        setRecord(prevState => ({
            ...prevState,
            [name]:date
        }));
    }

    const handleEditRecord = (record,e) => {
        setIdRecord(record.id);
        setRecord(
            {
                startDate:record.startDate,
                endDate:record.endDate,
                Mo:record.Mo,Tu:record.Tu,
                We:record.We,Th:record.Th,
                Fr:record.Fr,Sa:record.Sa,Su:record.Su,
                startTime:record.startTime,
                endTime:record.endTime,
                fileId:record.fileId,
                controlId:record.controlId}
        )
        setModalRecord(true);
    }

    const showPreview = (record) => {
        setFormat(record.split(".")[(record.split("/")).length-1]);
        if(record.split(".")[(record.split("/")).length-1] !== "pdf"){
            Storage.get(record)
                .then(data => {
                    setUrl(data);
                    setShowPreview(true);
                })
                .catch(err => {
                    console.log("error fetching image", err)
                })
        }
        else{
            Storage.get(record, {
                contentType:"application/pdf"
            })
                .then(data => {
                    setUrl(data);
                    setShowPreview(true);
                })
                .catch(err => {
                    console.log("error fetching image", err)
                })
        }
    }

    const handleShowDeleteRecord = (id,e) => {
        setIdRecord(id);
        setDeleteModalRecord(true);
    }

    const handleDeleteCloseRecord = () => {
        setDeleteModalRecord(false);
        setIdRecord("0");
    }

    const handleDeleteRecord = e => {
        setLoader(true);
        const data = {
            id:idRecord,
        }
        API.graphql(graphqlOperation(deleteRecord,{input:data})).then((data) => {
            createFileRecord(showSelectedContent);
            handleDeleteCloseRecord();
            getRecordList(showSelectedContent);
        }).catch((err) => {
            console.log('err',err);
            setLoader(false);
        });
    }

    const onRecord = () => {
        setLoader(true);
        if(idRecord === "0"){
            const data = {
                user:idUser,
                contentId:showSelectedContent,
                startDate:record.startDate,
                endDate:record.endDate,
                Mo:record.Mo, Tu:record.Tu,We:record.We,Th:record.Th,Fr:record.Fr,Sa:record.Sa,Su:record.Su,
                startTime:record.startTime,
                endTime:record.endTime,
                fileId:record.fileId,
                controlId:record.controlId
            };
            API.graphql(graphqlOperation(createRecord,{input:data})).then((data) => {
                createFileRecord(showSelectedContent);
            }).catch((err) => {
                console.log('err',err);
                setLoader(false);
            });
        }
        else{
            const data = {
                id:idRecord,
                startDate:record.startDate,
                endDate:record.endDate,
                Mo:record.Mo, Tu:record.Tu,We:record.We,Th:record.Th,Fr:record.Fr,Sa:record.Sa,Su:record.Su,
                startTime:record.startTime,
                endTime:record.endTime,
                fileId:record.fileId,
                controlId:record.controlId
            }
            API.graphql(graphqlOperation(updateRecord,{input:data})).then((data) => {
                createFileRecord(showSelectedContent);
            }).catch((err) => {
                console.log('err',err);
                setLoader(false);
            });
        }
    }

    const createFileRecord = (id) => {
        API.graphql(graphqlOperation(listRecords,{
            filter:{
                contentId:{
                    eq:id
                }
            }
        })).then((response) => {
            const recordList = response.data.listRecords.items;
            let text = "DateStart|DateStop|Monday|Thursday|Wednesday|Tuesday|Friday|Saturday|Sunday|TimeStart|TimeStop|FileName|NameField";
            recordList.forEach(recordInfo =>{
                let dateStart = recordInfo.startDate !== "" ? new Date(recordInfo.startDate).getFullYear()+"/"+
                                                            ("0" + (new Date(recordInfo.startDate).getMonth() + 1)).slice(-2)+"/"+
                                                            ("0" + new Date(recordInfo.startDate).getDate()).slice(-2)
                     : "";
                let dateStop = recordInfo.endDate !== "" ? new Date(recordInfo.endDate).getFullYear() +"/"+
                                                        ("0" + (new Date(recordInfo.endDate).getMonth() + 1)).slice(-2)+"/"+
                                                        ("0" + new Date(recordInfo.endDate).getDate()).slice(-2)
                     : "";
                let timeStart = recordInfo.startTime !== "" ? ("0" + new Date(recordInfo.startTime).getHours()).slice(-2)+":"+
                    ("0" + new Date(recordInfo.startTime).getMinutes()).slice(-2) : "";
                let timeStop = recordInfo.endTime !== "" ? ("0" + new Date(recordInfo.endTime).getHours()).slice(-2)+":"+
                    ("0" + new Date(recordInfo.endTime).getMinutes()).slice(-2) : "";
                let fileName = files.findIndex(obj => obj.key === recordInfo.fileId) !== -1 ?
                    (files[files.findIndex(obj => obj.key === recordInfo.fileId)].key).split("/")[((files[files.findIndex(obj => obj.key === recordInfo.fileId)].key).split("/")).length-1]
                    :
                    "";
                let name = controls.findIndex(obj => obj.id === recordInfo.controlId) !== -1 ?
                    controls[controls.findIndex(obj => obj.id === recordInfo.controlId)].name
                :
                    "";
                text = text+"\n"+dateStart+"|"
                    +dateStop+"|"
                    +recordInfo.Mo+"|"
                    +recordInfo.Th+"|"
                    +recordInfo.We+"|"
                    +recordInfo.Tu+"|"
                    +recordInfo.Fr+"|"
                    +recordInfo.Sa+"|"
                    +recordInfo.Su+"|"
                    +timeStart+"|"
                    +timeStop+"|"
                    +fileName+"|"
                    +name;
            });
            let index = selectContents.findIndex(obj => obj.id === id);
            let contentName = selectContents[index].name;
            const file = new Blob([text],
                {type:"text/plain;charset=utf-8"});
            let s3Path = idUser + "/" + contentName +".lst";
            Storage.put(s3Path,file).then(() =>{
                setLoader(false);
                setModalRecord(false);
                setRecords(recordList);
            })
            .catch((err)=>{
                console.log("upload err ",err);
                setLoader(false);
            })
        })
            .catch((err) =>{
                setLoader(false);
                console.log('error devices ',err);
            });
    }

    const getRecordList = (id) => {
        setLoader(true);
        API.graphql(graphqlOperation(listRecords,{
            filter:{
                contentId:{
                    eq:id
                }
            }
        })).then((response) => {
            const recordList = response.data.listRecords.items;
            setLoader(false);
            setRecords(recordList);
        })
        .catch((err) =>{
            setLoader(false);
            console.log('error devices ',err);
        });
    }

    const handleSignOutButtonClick = async () => {
        try {
            await Auth.signOut();
            Hub.dispatch('UI Auth', {   // channel must be 'UI Auth'
                event: 'AuthStateChange',    // event must be 'AuthStateChange'
                message: 'signedout'    // message must be 'signedout'
            });
        } catch (error) {
            console.log('error signing out: ', error);
        }
    };

    return authState === AuthState.SignedIn && authUser ? (
        <div>
            <Loader
                onShow={showLoader}/>
            <div style={{overscrollBehaviorY:"contain"}}>
                <div className="pageContentWrapper">
                    <TopBar
                        authInfo={authInfo}
                        view={view}
                        handleUser={handleModal}
                        handleDevice={handleDevice}
                        showContent={showContent}
                        showFiles={showFiles}
                        handleNewFile={handleNewFile}
                        handleNewContent={handleNewContent}
                        selectContents={selectContents}
                        showSelectedContent={showSelectedContent}
                        changeContent={changeContent}
                        handleNewControl={handleNewControl}
                        handleNewRecord={handleNewRecord}
                        handleDeleteContent={handleShowDeleteContent}
                        clearAll={clearAll}
                    />
                    <ModalDelete
                        show={showDeleteModal}
                        onHide={handleDeleteClose}
                        handleDelete={handleDelete}/>
                    <ModalDelete
                        show={showDeleteModalDevice}
                        onHide={handleDeleteCloseDevice}
                        handleDelete={handleDeleteDevice}/>
                    <ModalDelete
                        show={showDeleteModalRecord}
                        onHide={handleDeleteCloseRecord}
                        handleDelete={handleDeleteRecord}/>
                    <ModalDelete
                        show={showDeleteModalFile}
                        onHide={handleDeleteCloseFile}
                        handleDelete={handleDeleteFile}/>
                    <ModalDelete
                        show={showDeleteModalContent}
                        onHide={handleDeleteCloseContent}
                        handleDelete={handleDeleteContent}/>
                    <ModalPreview
                        show={showModalPreview}
                        onHide={handlePreviewCloseContent}
                        url={url}
                        format={format}
                    />
                    <NewEditUser
                        show={showModal}
                        onHide={handleClose}
                        authInfo={authInfo}
                        user={user}
                        id={idUser}
                        handleChange={handleChange}
                        errorUser={errorUser}
                        handleSelect={handleSelect}
                        commit={onUser}
                    />
                    <NewEditMac
                        show={showModalMac}
                        onHide={handleMacClose}
                        device={device}
                        id={idDevice}
                        authInfo={authInfo}
                        handleChange={handleChangeDevice}
                        errorDevice={errorDevice}
                        commit={onDevice}
                        contents={selectContents}
                    />
                    <EditMultipleContents
                        show={showEditContents}
                        onHide={handleContentsClose}
                        handleChange={handleChangeIdContent}
                        commit={updateMultipleContents}
                        selectContents={selectContents}
                    />
                    <NewEditContent
                        id={idContent}
                        show={showModalContent}
                        onHide={handleModalContentClose}
                        handleChange={handleChangeContent}
                        commit={onContent}
                        content={content}
                    />
                    <NewContentFile
                        show={showModalFile}
                        onHide={handleFileClose}
                        id={idFile}
                        handleChange={handleChangeFile}
                        commit={onFile}
                        error={fileError}
                    />
                    <NewEditControl
                        show={showModalControl}
                        onHide={handleModalControlClose}
                        idControl={idControl}
                        control={control}
                        controls={controls}
                        handleChange={handleChangeName}
                        handleSelectChange={handleSelectChangeControl}
                        setIdControl={setIdControlDefault}
                        delete={showModalDeleteControl}
                        commit={onControl}
                        showDeleteModalControl={showDeleteModalControl}
                        handleDeleteCloseControl={handleDeleteCloseControl}
                        handleDeleteControl={handleDeleteControl}
                    />
                    <NewEditRecord
                        show={showModalRecord}
                        onHide={handleModalRecordClose}
                        id={idRecord}
                        record={record}
                        handleChange={handleChangeRecord}
                        handleCheck={handleCheckRecord}
                        handleChangeDate={handleChangeDate}
                        controls={controls}
                        files={files}
                        commit={onRecord}
                    />
                    <div className="mainContainer">
                        <aside className="menuContainer">
                            <List style={{height:"100%",marginTop:"8px",display:"grid",gridTemplateRows:"auto 1fr auto"}}>
                                {/*
                                    <div>
                                        <ListItem
                                            button={false}
                                            className="menuButton"
                                        >
                                            <Avatar style={{ backgroundColor: "gray" }}><BarChart style={{width:"24px",height:"24px"}}/></Avatar>
                                            <Typography className="labelButtonMenu">Statistics</Typography>
                                        </ListItem>
                                        <ListItem
                                            button={false}
                                            className="menuButton"
                                        >
                                            <Avatar style={{ backgroundColor: "gray" }}><ShowChart style={{width:"24px",height:"24px"}}/></Avatar>
                                            <Typography className="labelButtonMenu">Device Stats</Typography>
                                        </ListItem>
                                        <ListItem
                                            button={false}
                                            className="menuButton"
                                        >
                                            <Avatar style={{ backgroundColor: "gray" }}><Devices style={{width:"24px",height:"24px"}}/></Avatar>
                                            <Typography className="labelButtonMenu">Devices</Typography>
                                        </ListItem>
                                        <ListItem
                                            button={false}
                                            className="menuButton"
                                        >
                                            <Avatar style={{ backgroundColor: "gray" }}><Room style={{width:"24px",height:"24px"}}/></Avatar>
                                            <Typography className="labelButtonMenu">Map</Typography>
                                        </ListItem>
                                        <ListItem
                                            button={false}
                                            className="menuButton"
                                        >
                                            <Avatar style={{ backgroundColor: "gray" }}><TouchApp style={{width:"24px",height:"24px"}}/></Avatar>
                                            <Typography className="labelButtonMenu">Touch Map</Typography>
                                        </ListItem>
                                        <ListItem
                                            button={false}
                                            className="menuButton"
                                        >
                                            <Avatar style={{ backgroundColor: "gray" }}><InfoOutlined style={{width:"24px",height:"24px"}}/></Avatar>
                                            <Typography className="labelButtonMenu">Info</Typography>
                                        </ListItem>
                                    </div>
                                */}
                                <div style={{gridRow:"3"}}>
                                    <ListItem
                                        button={true}
                                        onClick={handleSignOutButtonClick}
                                        className="menuButton"
                                    >
                                        <Avatar style={{backgroundColor:"#483d8b"}}>
                                            <MdExitToApp style={{width:"24px",height:"24px"}}/>
                                        </Avatar>
                                        <Typography className="labelButtonMenu">
                                            Sign Out
                                        </Typography>
                                    </ListItem>
                                    {/*
                                        <div style={{marginBottom:"1px"}}>
                                            <div className="qc_chart_wrapper">
                                                <span className='qc_chart_val qc_chart_val_left'>
                                                    {'0%'}
                                                </span>
                                                <div className='qc_chart'>
                                                    <div className='qc_chart_bg_wrapper'/>
                                                    <div className='qc_chart_bg'/>
                                                    <div
                                                        className='qc_chart_line'
                                                        style={{transform: `rotate(0deg)`, bottom: `0px`}}
                                                    />
                                                    <div className='qc_chart_val_wrapper'>
                                                        <span className='qc_chart_main_val'>
                                                            0%
                                                        </span>
                                                    </div>
                                                </div>
                                                <span className='qc_chart_val qc_chart_val_right'>
                                                    {'100%'}
                                                </span>
                                            </div>
                                            <p className='qc_chart_lable'>
                                                Data Limit
                                            </p>
                                        </div>
                                    */}
                                </div>
                            </List>
                        </aside>
                        <Collapse className="collapseContainer" in={view === 1}>
                            <div style={{flexGrow:"1"}}>
                                <div className="divParent">
                                    <div className="buttonLeft">
                                        <Button className="buttonColor" onClick={handleModal}>Add User</Button>
                                    </div>
                                    <FilterTableComponent
                                        columns={columnsUser}
                                        data = {users}
                                        backFnc = {""}
                                        height = {heightTable}
                                        limit = {null}
                                        trafficLimit = {null}
                                        home = {true}
                                    />
                                </div>
                            </div>
                        </Collapse>
                        <Collapse className="collapseContainer" in={view === 2}>
                            <div style={{flexGrow:"1"}}>
                                <div className="divParent">
                                    {
                                        authInfo.admin && !authInfo.superUser || !authInfo.admin && authInfo.superUser || authInfo.admin && authInfo.superUser ?
                                            <div className="buttonLeft">
                                                <Button className="buttonColor" onClick={handleDevice}>Add Device</Button>
                                                <Button className="buttonColor" onClick={(e)=> showContent("",e)} style={{marginLeft:"10px"}}>Show Content Package</Button>
                                                <Button className="buttonColor" onClick={(e)=> showFiles(e)} style={{marginLeft:"10px"}}>Show Multimedia Assets</Button>
                                            </div>
                                            :
                                            <></>
                                    }
                                    {
                                        !authInfo.admin && !authInfo.superUser ?
                                            <div className="buttonLeft">
                                                <Button className="buttonColor" onClick={(e)=> showContent("",e)}>Show Content Package</Button>
                                                <Button className="buttonColor" onClick={(e)=> showFiles(e)} style={{marginLeft:"10px"}}>Show Multimedia Assets</Button>
                                            </div>
                                            :
                                            <></>
                                    }
                                    {
                                        authInfo.superUser ?
                                            <SelectionTableComponent
                                                columns={columnsDevice}
                                                data = {devices}
                                                handleSelectDevice={handleSelectDevice}
                                                backFnc = {closeMac}
                                                height = {heightTable}
                                                home = {true}
                                            />
                                            :
                                            authInfo.admin ?
                                                <SelectionTableComponent
                                                    columns={columnsDevice}
                                                    data = {devices}
                                                    handleSelectDevice={handleSelectDevice}
                                                    backFnc = {closeMac}
                                                    height = {heightTable}
                                                    home = {true}
                                                />
                                                :
                                                <SelectionTableComponent
                                                    columns={columnsDevice}
                                                    data = {devices}
                                                    handleSelectDevice={handleSelectDevice}
                                                    backFnc = {""}
                                                    height = {heightTable}
                                                    home = {true}
                                                />
                                    }
                                    <FilterTableComponent
                                        columns={columnsHistory}
                                        data = {historyArray}
                                        backFnc = {""}
                                        height = {heightTable}
                                        limit = {limitObj}
                                        trafficLimit = {monthlyTrafficLimit}
                                        home = {true}
                                    />
                                </div>
                            </div>
                        </Collapse>
                        <Collapse className="collapseContainer" in={view === 3}>
                            <div style={{flexGrow:"1"}}>
                                <div className="divParent">
                                    <div className="buttonLeft">
                                        <Button className="buttonColor" onClick={handleNewRecord} disabled={showSelectedContent === "0"}>Add Content</Button>
                                        {
                                            authInfo.admin || authInfo.superUser ?
                                                <Button className="buttonColor" onClick={handleNewControl} style={{marginLeft:"10px"}}>UI Variable</Button>
                                                :
                                                <></>
                                        }
                                        <div style={{marginTop:"5px",textAlign:"left"}}>
                                            <Form>
                                                <Form.Group style={{"marginBottom":"0px"}} >
                                                    <Form.Control as="select" custom name="content" style={{"width":"40%","maxWidth":"340px"}} onChange={changeContent} value={showSelectedContent}>
                                                        <option value={0} name="content">Select Content Package ID</option>
                                                        {
                                                            selectContents.map(content => (
                                                                <option value={content.id} name="content">{content.name}</option>
                                                            ))
                                                        }
                                                    </Form.Control>
                                                    <MdAdd style={{cursor:'pointer',fontSize:"1.5em",marginLeft:"5px"}} onClick={handleNewContent}/>
                                                    {
                                                        showSelectedContent === "0" ?
                                                            <>
                                                                <MdDeleteForever style={{fontSize:"1.5em",marginLeft:"5px",color:"grey"}} />
                                                            </>
                                                            :
                                                            <>
                                                                <MdDeleteForever style={{cursor:'pointer',fontSize:"1.5em",marginLeft:"5px"}} onClick={handleDeleteContent}/>
                                                            </>
                                                    }

                                                </Form.Group>
                                            </Form>
                                        </div>
                                    </div>
                                    <FilterTableComponent
                                        columns={columnsRecord}
                                        data = {records}
                                        backFnc = {closeContent}
                                        height = {heightTable}
                                        limit = {null}
                                        trafficLimit = {null}
                                        home = {!(authInfo.superUser || authInfo.admin)}
                                    />
                                </div>
                            </div>
                        </Collapse>
                        <Collapse className="collapseContainer" in={view === 4}>
                            <div style={{flexGrow:"1"}}>
                                <div className="divParent">
                                    <div className="buttonLeft">
                                            <Button className="buttonColor" onClick={handleNewFile} disabled={limitUplaod.label >= limitDataUpload}>Upload File</Button>
                                    </div>
                                    <FilterTableComponent
                                        columns={columnsFile}
                                        data = {files}
                                        backFnc = {closeContent}
                                        height = {heightTable}
                                        limit = {limitUplaod}
                                        trafficLimit = {limitDataUpload}
                                        home = {!(authInfo.superUser || authInfo.admin)}
                                    />
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </div>
                {
                    showBtnContents ?
                        <div className="multiContents">
                            <Button className="buttonColor" onClick={(e) => setEditContents(true)}><MdEdit style={{cursor:'pointer',fontSize:"1.5em"}}/>Set Multi Contents</Button>
                        </div>
                        :
                        <></>
                }
            </div>
        </div>
    ) : (
        <Authenticator/>
    );
}

export default AuthStateApp;