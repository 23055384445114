import React, {useEffect} from "react";

import {useTable, useFilters, usePagination, useSortBy,useRowSelect} from 'react-table'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/App.css';
import {MdArrowBack,MdHome} from "react-icons/md/index";


function DefaultColumnFilter({
                                 column: { filterValue, preFilteredRows, setFilter },
                             }) {
    const count = preFilteredRows.length

    return (
        <input
            className="form-control inputFilter"
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            placeholder={`Search ${count} records...`}
        />
    )
}

export const SelectionTableComponent = (props) => {
    const select = props.selectContents;
    const columns = props.columns;
    const data = props.data;
    const backFnc = props.backFnc;
    const home = props.home;
    const divHeight = props.height;

    const defaultColumn = React.useMemo(
        () => ({
            // Default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        selectedFlatRows,
        state: { pageIndex, pageSize, selectedRowIds },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                        </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) =>
                        (
                            <div>
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                            </div>
                        ),
                },
                ...columns,
            ])
        }
    )

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }, ref) => {
            const defaultRef = React.useRef()
            const resolvedRef = ref || defaultRef

            React.useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate
            }, [resolvedRef, indeterminate])

            return (
                <>
                    <input type="checkbox" ref={resolvedRef} {...rest} />
                </>
            )
        }
    )
    useEffect(() =>{
        props.handleSelectDevice(selectedFlatRows);
    },[selectedFlatRows]);
    return (
        <div className="tableDiv" style={{height:divHeight+"%"}}>
            <div className="tableOverflow">
                <table className="table table-striped table-hover table-sm" style={{textAlign:"left",}} {...getTableProps()}>
                    <thead className="tableHeader">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <>
                                    {
                                        column.id !== "selection" ?
                                            column.Header !== "" ?
                                                <th style={{border:"0px"}}>
                                                    <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                        {column.render('Header')}
                                                        {column.isSorted
                                                            ? column.isSortedDesc
                                                                ? <label style={{margin:"0px",marginLeft:"5px",fontSize:"12px"}}> 🔽</label>
                                                                : <label style={{margin:"0px",marginLeft:"5px",fontSize:"12px"}}> 🔼</label>
                                                            : <label style={{mixBlendMode:"soft-light",margin:"0px",marginLeft:"5px",fontSize:"12px"}}> 🔽</label>}
                                                    </div>
                                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                                </th>
                                            :
                                                backFnc !== "" ?
                                                    home !== true ?
                                                        <th style={{textAlignLast:"center",border:"0px",minWidth:"92px"}}>
                                                            <MdArrowBack style={{cursor:'pointer',fontSize:"2em"}} onClick={(e)=> backFnc(e)}/>
                                                        </th>
                                                    :
                                                        <th style={{textAlignLast:"center",border:"0px",minWidth:"92px"}}>
                                                            <MdHome style={{cursor:'pointer',fontSize:"2em"}} onClick={(e)=> backFnc(e)}/>
                                                        </th>
                                                :
                                                    <th style={{border:"0px",minWidth:"92px"}}/>
                                            :
                                                <th style={{border:"0px"}}>
                                                    <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                        {column.render('Header')}
                                                        {column.isSorted
                                                            ? column.isSortedDesc
                                                                ? <label style={{margin:"0px",marginLeft:"5px"}}> 🔽</label>
                                                                : <label style={{margin:"0px",marginLeft:"5px"}}> 🔼</label>
                                                            : ""}
                                                    </div>
                                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                                </th>
                                    }
                                </>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            <div className="paginationView">
                <ul className="pagination" style={{alignItems:"center",marginBottom:"3px"}}>
                    <li style={{fontSize:"14px"}}>Rows:</li>
                    <select
                        style={{width:"42px"}}
                        className="selectPagination"
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                    >
                        {[5, 10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                    <li className="page-item" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        <a className="page-link">First</a>
                    </li>
                    <li className="page-item" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <a className="page-link">{'<'}</a>
                    </li>
                    <li className="page-item" onClick={() => nextPage()} disabled={!canNextPage}>
                        <a className="page-link">{'>'}</a>
                    </li>
                    <li className="page-item" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        <a className="page-link">Last</a>
                    </li>
                    <li>
                        <a className="page-link">
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </a>
                    </li>
                    <li>
                        <a className="page-link" style={{padding:"0px"}}>
                            <input
                                className="form-control, selectPage"
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    gotoPage(page)
                                }}
                            />
                        </a>
                    </li>{' '}
                </ul>
            </div>
        </div>
    )


    /*return (
        <Table columns={props.columns} data={props.data} />
    )*/
};