/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      description
      location
      admin
      group
      superUser
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      description
      location
      admin
      group
      superUser
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      description
      location
      admin
      group
      superUser
      createdAt
      updatedAt
    }
  }
`;
export const createDevice = /* GraphQL */ `
  mutation CreateDevice(
    $input: CreateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    createDevice(input: $input, condition: $condition) {
      id
      mac
      user
      address
      content
      customer
      description
      group
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice(
    $input: UpdateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    updateDevice(input: $input, condition: $condition) {
      id
      mac
      user
      address
      content
      customer
      description
      group
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice(
    $input: DeleteDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    deleteDevice(input: $input, condition: $condition) {
      id
      mac
      user
      address
      content
      customer
      description
      group
      type
      createdAt
      updatedAt
    }
  }
`;
export const batchAddDevice = /* GraphQL */ `
  mutation BatchAddDevice($input: [BatchUpdateDeviceInput!]!) {
    batchAddDevice(input: $input) {
      id
      mac
      user
      address
      content
      customer
      description
      group
      type
      createdAt
      updatedAt
    }
  }
`;
export const createContent = /* GraphQL */ `
  mutation CreateContent(
    $input: CreateContentInput!
    $condition: ModelContentConditionInput
  ) {
    createContent(input: $input, condition: $condition) {
      id
      name
      user
      createdAt
      updatedAt
    }
  }
`;
export const updateContent = /* GraphQL */ `
  mutation UpdateContent(
    $input: UpdateContentInput!
    $condition: ModelContentConditionInput
  ) {
    updateContent(input: $input, condition: $condition) {
      id
      name
      user
      createdAt
      updatedAt
    }
  }
`;
export const deleteContent = /* GraphQL */ `
  mutation DeleteContent(
    $input: DeleteContentInput!
    $condition: ModelContentConditionInput
  ) {
    deleteContent(input: $input, condition: $condition) {
      id
      name
      user
      createdAt
      updatedAt
    }
  }
`;
export const createControl = /* GraphQL */ `
  mutation CreateControl(
    $input: CreateControlInput!
    $condition: ModelControlConditionInput
  ) {
    createControl(input: $input, condition: $condition) {
      id
      name
      group
      createdAt
      updatedAt
    }
  }
`;
export const updateControl = /* GraphQL */ `
  mutation UpdateControl(
    $input: UpdateControlInput!
    $condition: ModelControlConditionInput
  ) {
    updateControl(input: $input, condition: $condition) {
      id
      name
      group
      createdAt
      updatedAt
    }
  }
`;
export const deleteControl = /* GraphQL */ `
  mutation DeleteControl(
    $input: DeleteControlInput!
    $condition: ModelControlConditionInput
  ) {
    deleteControl(input: $input, condition: $condition) {
      id
      name
      group
      createdAt
      updatedAt
    }
  }
`;
export const createControlName = /* GraphQL */ `
  mutation CreateControlName(
    $input: CreateControlNameInput!
    $condition: ModelControlNameConditionInput
  ) {
    createControlName(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateControlName = /* GraphQL */ `
  mutation UpdateControlName(
    $input: UpdateControlNameInput!
    $condition: ModelControlNameConditionInput
  ) {
    updateControlName(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteControlName = /* GraphQL */ `
  mutation DeleteControlName(
    $input: DeleteControlNameInput!
    $condition: ModelControlNameConditionInput
  ) {
    deleteControlName(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createRecord = /* GraphQL */ `
  mutation CreateRecord(
    $input: CreateRecordInput!
    $condition: ModelRecordConditionInput
  ) {
    createRecord(input: $input, condition: $condition) {
      id
      user
      contentId
      startDate
      endDate
      Su
      Mo
      Tu
      We
      Th
      Fr
      Sa
      startTime
      endTime
      fileId
      controlId
      createdAt
      updatedAt
    }
  }
`;
export const updateRecord = /* GraphQL */ `
  mutation UpdateRecord(
    $input: UpdateRecordInput!
    $condition: ModelRecordConditionInput
  ) {
    updateRecord(input: $input, condition: $condition) {
      id
      user
      contentId
      startDate
      endDate
      Su
      Mo
      Tu
      We
      Th
      Fr
      Sa
      startTime
      endTime
      fileId
      controlId
      createdAt
      updatedAt
    }
  }
`;
export const deleteRecord = /* GraphQL */ `
  mutation DeleteRecord(
    $input: DeleteRecordInput!
    $condition: ModelRecordConditionInput
  ) {
    deleteRecord(input: $input, condition: $condition) {
      id
      user
      contentId
      startDate
      endDate
      Su
      Mo
      Tu
      We
      Th
      Fr
      Sa
      startTime
      endTime
      fileId
      controlId
      createdAt
      updatedAt
    }
  }
`;
