import React from 'react'
import '../css/loader.css';

export const Loader = (props) => {

    return(
        props.onShow ?
            <div className="d-flex flex-column align-items-center justify-content-center loader">
                <div className="row">
                    <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                <div className="row">
                    <strong style={{color:"white"}}>Loading...</strong>
                </div>

            </div>
            :
            <></>
    );

}