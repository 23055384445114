import React from 'react'
import {Modal, Button} from "react-bootstrap";

export const NewEditContent = (props) => {

    const borderColor = {
        warning: {
            borderColor:"red"
        },
        default: {
        },
    };

    return(
        <>
            <Modal show={props.show} onHide={props.onHide} backdrop="static">
                <Modal.Header closeButton>
                    {
                        props.id === "0" ? <h4>Add Content Package</h4> : <h4>Edit Content Package</h4>
                    }
                </Modal.Header>
                <Modal.Body style={{display:"grid",textAlign:"center"}}>
                        <p>Name</p>
                        <input onChange={props.handleChange} type="text" name="name" value={props.content.name}/>
                        <br/>
                </Modal.Body>
                <Modal.Footer>
                    {
                        props.id === "0" ? <Button onClick={props.commit}>Create</Button> : <Button onClick={props.commit}>Edit</Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )

}