import React from 'react'
import {Button} from "react-bootstrap";
import {DialogTitle, DialogContent, Dialog, DialogActions, useTheme, useMediaQuery} from '@mui/material';
import '../App.css';

export const NewEditUser = (props) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const borderColor = {
        warning: {
            borderColor:"red"
        },
        default: {
        },
    };

    return(
        <>
        <Dialog
            open={props.show}
            fullScreen={fullScreen}
            onClose={props.onHide}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth="true"
            maxWidth="sm"
            className="backgroundDialog"
        >
            <DialogTitle className="dialogTitle">
                {
                    props.id === "0" ? <h4>Add user</h4> : <h4>Edit {props.user.username}</h4>
                }
            </DialogTitle>
            <DialogContent style={{display:"grid",textAlign:"center"}}>
                {
                    props.id === "0" ?
                        <>
                            <p>Email</p>
                            <input value={props.user.username} onChange={props.handleChange} style={props.errorUser.username === true ? {borderColor:"red"} : borderColor.default} type="email" disabled={props.id !== "0"} name="username"/>
                            <p>Password</p>
                            <input value={props.user.password} onChange={props.handleChange} style={props.errorUser.password === true ? {borderColor:"red"} : borderColor.default} type="text" disabled={props.id !== "0"} name="password"/>
                        </>
                    :
                        <>
                        </>
                    //<Button className="btn btn-secondary">Update Password</Button>
                }
                <p style={{marginTop:"1rem"}}>Administrator</p>
                <input style={{placeSelf:"center"}}type="checkbox" onChange={(e) => props.handleSelect(props.user.admin,e)} checked={props.user.admin || false} name="admin"/>
                {
                    props.authInfo.superUser ?
                        <>
                            <p style={{marginTop:"1rem"}}>Group</p>
                            <input value={props.user.group} onChange={props.handleChange} style={props.errorUser.group === true ? {borderColor:"red"} : borderColor.default} type="text" name="group"/>
                        </>
                        :
                        <p></p>
                }
                <p>Description</p>
                <input value={props.user.description} onChange={props.handleChange} type="text" name="description"/>
                <p>Location</p>
                <input value={props.user.location} onChange={props.handleChange} type="text" name="location"/>
            </DialogContent>
            <DialogActions>
                <Button className="btn btn-secondary mr-auto" onClick={props.onHide}>Cancel</Button>
                {
                    props.id === "0" ? <Button onClick={props.commit}>Create</Button> : <Button onClick={props.commit}>Edit</Button>
                }
            </DialogActions>
        </Dialog>
        </>
    )

}