import React, {useRef, useState} from 'react'
import {Button} from "react-bootstrap";
import {MdPlayCircleOutline,MdPauseCircleFilled} from 'react-icons/md'
import {DialogTitle, DialogContent, Dialog, DialogActions, useTheme, useMediaQuery} from '@mui/material';
import '../App.css';

export const ModalPreview = (props) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [disabled,setDisabled] = useState(false);
    const videoRef = useRef(null);

    const file = new Blob([props.url],{type: 'application/pdf'});
    const fileURL = URL.createObjectURL(file);

    const playVideo = () => {
        setDisabled(true);
        videoRef.current.play();
    }
    const pauseVideo = () => {
        setDisabled(false);
        videoRef.current.pause();
    }

    return(
        <>
            <Dialog
                open={props.show}
                fullScreen={fullScreen}
                onClose={props.onHide}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth="true"
                maxWidth="sm"
                className="backgroundDialog"
            >
                <DialogTitle className="dialogTitle">
                    <h4>Preview</h4>
                </DialogTitle>
                <DialogContent style={{display:"grid",textAlign:"center",marginTop:"10px"}}>
                    {
                        props.format === "png" ||
                        props.format === "jpg" ||
                        props.format === "jpeg" ||
                        props.format === "gif" ||
                        props.format === "bmp" ?
                            <img src={props.url} style={{width:"100%",height:"100%"}}/>
                        :
                            ""
                    }
                    {
                        props.format === "mp4" ?
                            <div>
                                <video ref={videoRef} src={props.url} type="video/mp4" width="100%" height="85%"/>
                                {
                                    !disabled ?
                                        <div>
                                            <MdPlayCircleOutline style={{cursor:'pointer',fontSize:"2.5em"}} onClick={playVideo.bind(this)}/>
                                            <MdPauseCircleFilled disabled={!disabled} style={{cursor:'pointer',fontSize:"2.5em",color:"grey"}} onClick={pauseVideo.bind(this)}/>
                                        </div>
                                    :
                                        <div>
                                            <MdPlayCircleOutline style={{cursor:'pointer',fontSize:"2.5em",color:"grey"}} onClick={playVideo.bind(this)}/>
                                            <MdPauseCircleFilled disabled={!disabled} style={{cursor:'pointer',fontSize:"2.5em"}} onClick={pauseVideo.bind(this)}/>
                                        </div>
                                }

                            </div>
                        :
                            ""
                    }
                    {
                        props.format === "pdf" ?
                            <div style={{height:"50vh"}}>
                                <iframe src={props.url} width='100%' height='100%' frameBorder='0'/>
                            </div>
                        :
                            ""
                    }
                    {
                        props.format !== "png" &&
                        props.format !== "jpg" &&
                        props.format !== "jpeg" &&
                        props.format !== "gif" &&
                        props.format !== "bmp" &&
                        props.format !== "mp4" &&
                        props.format !== "pdf" ?
                           "No Preview"
                        :
                            ""
                    }
                </DialogContent>
                <DialogActions>
                    <Button className="btn btn-secondary mr-auto" onClick={props.onHide}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );

}