import React from 'react'
import {Button} from "react-bootstrap";
import {DialogTitle, DialogContent, Dialog, DialogActions, useTheme, useMediaQuery} from '@mui/material';
import '../App.css';

export const NewContentFile = (props) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const borderColor = {
        warning: {
            borderColor:"red"
        },
        default: {
        },
    };

    return (
        <>
            <Dialog
                open={props.show}
                fullScreen={fullScreen}
                onClose={props.onHide}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth="true"
                maxWidth="sm"
                className="backgroundDialog"
            >
                <DialogTitle className="dialogTitle">
                    {
                        props.id === "0" ? <h4>Add File</h4> : <h4>Edit File {props.idMac}</h4>
                    }
                </DialogTitle>
                <DialogContent style={{display:"grid",textAlign:"center",marginTop:"10px"}}>
                    {
                        props.error === false ?
                            <input type="file" name="file" onChange={props.handleChange}/>
                        :
                            <input type="file" name="file" onChange={props.handleChange} style={{backgroundColor:"red"}}/>
                    }
                </DialogContent>
                <DialogActions>
                    <Button className="btn btn-secondary mr-auto" onClick={props.onHide}>Cancel</Button>
                    {
                        props.id === "0" ? <Button onClick={props.commit}>Upload</Button> : <Button onClick={props.commit}>Edit</Button>
                    }
                </DialogActions>
            </Dialog>
        </>
    )
}