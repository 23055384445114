/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:af2eea03-87c2-45eb-81f9-c413cda35bc5",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_GYATknjpt",
    "aws_user_pools_web_client_id": "2fhm5ni11n6h6pac588f4sf2e0",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://aez5e3q26vbnhk5coeh5tjjhf4.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "cms134823-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
