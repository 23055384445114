/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      description
      location
      admin
      group
      superUser
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        description
        location
        admin
        group
        superUser
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDevice = /* GraphQL */ `
  query GetDevice($id: ID!) {
    getDevice(id: $id) {
      id
      mac
      user
      address
      content
      customer
      description
      group
      type
      createdAt
      updatedAt
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mac
        user
        address
        content
        customer
        description
        group
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContent = /* GraphQL */ `
  query GetContent($id: ID!) {
    getContent(id: $id) {
      id
      name
      user
      createdAt
      updatedAt
    }
  }
`;
export const listContents = /* GraphQL */ `
  query ListContents(
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        user
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getControl = /* GraphQL */ `
  query GetControl($id: ID!) {
    getControl(id: $id) {
      id
      name
      group
      createdAt
      updatedAt
    }
  }
`;
export const listControls = /* GraphQL */ `
  query ListControls(
    $filter: ModelControlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listControls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        group
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getControlName = /* GraphQL */ `
  query GetControlName($id: ID!) {
    getControlName(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listControlNames = /* GraphQL */ `
  query ListControlNames(
    $filter: ModelControlNameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listControlNames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRecord = /* GraphQL */ `
  query GetRecord($id: ID!) {
    getRecord(id: $id) {
      id
      user
      contentId
      startDate
      endDate
      Su
      Mo
      Tu
      We
      Th
      Fr
      Sa
      startTime
      endTime
      fileId
      controlId
      createdAt
      updatedAt
    }
  }
`;
export const listRecords = /* GraphQL */ `
  query ListRecords(
    $filter: ModelRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        contentId
        startDate
        endDate
        Su
        Mo
        Tu
        We
        Th
        Fr
        Sa
        startTime
        endTime
        fileId
        controlId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
