import React from 'react'
import {Table,Button, Form} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTheme,useMediaQuery, DialogTitle, DialogContent, Dialog, DialogActions } from '@mui/material';
import '../App.css';

export const NewEditRecord = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return(
        <>
            <Dialog
                open={props.show}
                fullScreen={fullScreen}
                onClose={props.onHide}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth="true"
                maxWidth="sm"
                className="backgroundDialog"
            >
                <DialogTitle className="dialogTitle">
                    {
                        props.id === "0" ? <h4>Add Content</h4> : <h4>Edit Content</h4>
                    }
                </DialogTitle>
                <DialogContent style={{display:"grid",textAlign:"center"}}>
                    <Form>
                        <Form.Group >
                            <p>UI Variable</p>
                            <Form.Control as="select" custom onChange={props.handleChange} name="controlId" value={props.record.controlId}>
                                <option value="" name="control">No UI Variable</option>
                                {
                                    props.controls.map(control => (
                                        <option key={control.id} value={control.id} name="control">{control.name}</option>
                                    ))
                                }
                            </Form.Control>
                        </Form.Group>
                    </Form>
                    <Form>
                        <Form.Group >
                            <p>File</p>
                                <Form.Control as="select" custom onChange={props.handleChange} name="fileId" value={props.record.fileId}>
                                <option value="" name="file">No file</option>
                                {
                                    props.files.map(file => (
                                        <option key={file.key} value={file.key} name="file">{file.key.split("/")[(file.key.split("/")).length-1]}</option>
                                    ))
                                }
                            </Form.Control>
                        </Form.Group>
                    </Form>

                    <div>
                        <Table>
                            <thead>
                            <tr>
                                <th>Su</th>
                                <th>Mo</th>
                                <th>Tu</th>
                                <th>We</th>
                                <th>Th</th>
                                <th>Fr</th>
                                <th>Sa</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                    <td><input type="checkbox" name="Su"
                                            onChange={(e) => props.handleCheck(props.record.Su, e)}
                                            checked={props.record.Su || false}/></td>
                                    <td><input type="checkbox" name="Mo"
                                               onChange={(e) => props.handleCheck(props.record.Mo, e)}
                                               checked={props.record.Mo || false}/></td>
                                    <td><input type="checkbox" name="Tu"
                                               onChange={(e) => props.handleCheck(props.record.Tu, e)}
                                               checked={props.record.Tu || false}/></td>
                                    <td><input type="checkbox" name="We"
                                               onChange={(e) => props.handleCheck(props.record.We, e)}
                                               checked={props.record.We || false}/></td>
                                    <td><input type="checkbox" name="Th"
                                               onChange={(e) => props.handleCheck(props.record.Th, e)}
                                               checked={props.record.Th || false}/></td>
                                    <td><input type="checkbox" name="Fr"
                                               onChange={(e) => props.handleCheck(props.record.Fr, e)}
                                               checked={props.record.Fr || false}/></td>
                                    <td><input type="checkbox" name="Sa"
                                               onChange={(e) => props.handleCheck(props.record.Sa, e)}
                                               checked={props.record.Sa || false}/></td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div>
                        <Table>
                            <thead>
                            <tr>
                                <th>Start date</th>
                                <th>End date</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                     <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date()}
                                        selected={props.record.startDate === "" ? null : new Date(props.record.startDate)}
                                        onChange={(date) => props.handleChangeDate(date,"startDate")}
                                        isClearable={true}
                                    />
                                </td>
                                <td>
                                     <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date()}
                                        selected={props.record.endDate === "" ? null : new Date(props.record.endDate)}
                                        onChange={(date) => props.handleChangeDate(date,"endDate")}
                                        isClearable={true}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div>
                        <Table>
                            <thead>
                            <tr>
                                <th>Start time</th>
                                <th>End time</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <DatePicker
                                    selected={props.record.startTime === "" ? null : new Date(props.record.startTime)}
                                    onChange={(date) => props.handleChangeDate(date,"startTime")}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    isClearable={true}
                                    />
                                </td>
                                <td>
                                    <DatePicker
                                    selected={props.record.endTime === "" ? null : new Date(props.record.endTime)}
                                    onChange={(date) => props.handleChangeDate(date,"endTime")}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    isClearable={true}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button className="btn btn-secondary mr-auto" onClick={props.onHide}>Cancel</Button>
                    {
                        props.id === "0" ? <Button onClick={props.commit}>Create</Button> : <Button onClick={props.commit}>Edit</Button>
                    }
                </DialogActions>
            </Dialog>
        </>
    )

}